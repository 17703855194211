<template>
  <!-- User sign in section -->
  <div class="userContainer container-fluid text-center" v-if="!loged">
    <h1 class="sign-in">Sign up</h1>
    <div class="form-group">
      <label class="font-weight-bold" for="user" maxlength="20">Username:</label>
      <input type="text" class="form-control" v-model="localUser.user" placeholder="user...">
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="pass" maxlength="20">Password:</label>
      <input type="password" class="form-control" v-model="localUser.pass" placeholder="******">
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="pass" maxlength="20">Repeat password:</label>
      <input type="password" class="form-control" v-model="localUser.pass2" placeholder="******">
    </div>
    <button 
      class="btn btn-success form-control" 
      :disabled="localUser.user.length == 0 || localUser.pass.length == 0 || localUser.pass2.length == 0 "
      @click="validateData">
      Sign up
    </button>
    <div class="error" v-if="errored">
      <p class="text text-danger">{{ error }}</p>
    </div>
    <div class="message" v-if="success">
      <p class="text text-success">Well done!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signup',
   data() {
    return {
      localUser: {
        user: '',
        pass: '',
        pass2: ''
      },
      loged: false,
      errored: false,
      error: '',
      success: false,
    }
  },
  methods: {
    // Sign in 
    validateData() {
      if (this.localUser.pass == this.localUser.pass2) {
        this.signUp();
      } else {
        this.errored = true;
        this.error = 'Passwords do not match'
      }
    },
    async signUp() {
      var datos = new FormData();
      datos.append("user", this.localUser.user);
      datos.append("pass", this.localUser.pass);
      var url = 'https://cronos8studio.com/smallnotesBack/php/addUser.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // validate
            if (jsonResponse.ok) {
              this.clearForm();
              this.success = true;
              setTimeout(() => {
                this.$router.push({ name: 'User' });
              }, 1500)
              // reset error msg
              this.errored = false;
              this.error = ''
            }
        }
      } catch(error) {
        this.errored = true;
        this.error = error
      }
    },
    // Clear
    clearForm() {
      this.localUser.user = '';
      this.localUser.pass = '';
      this.localUser.pass2 = '';
    }
  },
  // Execute when mounted
  mounted() {
    this.$nextTick(function () {
      // Check Token
    })
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.green {
  color: #42b883;
}
.spinner-grow {
  margin: 5px;
}
.userContainer {
  margin-top: 14vh;
  height: auto;
  width: 100%;
  padding-right: 2vh;
  padding-left: 2vh;
}
.sign-in {
  font-size: 5vh;
  padding: 1vh;
}
.wellcome {
  font-size: 5vh;
  padding: 1vh;
}
.user {
  font-size: 2vh;
  padding: 1vh;
}
.form-group label {
  font-size: 2vh;
  padding: 1vh;
}
.form-group input {
  height: 5vh;
  padding: 1vh;
  font-size: 2vh;
}
.btn {
  height: 6vh;
  margin-top: 2vh;
  font-size: 2vh;
}
.error {
  width: 100%;
  height: auto;
  font-size: 2vh;
  margin-top: 3vh;
}
.message {
  width: 100%;
  height: auto;
  font-size: 2vh;
  margin-top: 3vh;
}
</style>